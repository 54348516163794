<template>
	<div class="row">
		<div class="col-12 settings-wrapper">
			<q-input
				class="q-mt-md"
				label="Friendly Name"
				outlined
				bottom-slots
				v-model="friendly_name"
			/>
		</div>
		<div class="col-12 settings-wrapper">
			<q-select
				label="Dataset"
				class="q-my-sm"
				:value="customer_groups.find((it) => it.value == instance.dataset)"
				@input="setDataset"
				:options="customer_groups"
				hint="Customer Dataset in which to import new users"
				emit-values
				map-options
				:error="rti_validation_errors['dataset'] ? true : false"
				:error-message="rti_validation_errors['dataset']"
			/>
		</div>
		<div class="col-12 settings-wrapper">
			<time-zone-picker
				style="padding-left: 0; padding-right: 0"
				@timezoneSelected="updateInstance($event, 'timezone')"
				:existing_settings="instance.timezone"
			/>
		</div>
		<div class="col-12 settings-wrapper">
			<q-select
				class="q-mt-sm"
				label="Type of registration"
				:value="instance.registration_type"
				@input="setRegistrationType"
				:options="registration_type_options"
				:error="rti_validation_errors['registration_type'] ? true : false"
				:error-message="rti_validation_errors['registration_type']"
			>
			</q-select>
		</div>
		<!-- EMAIL Settings -->
		<div
			v-if="instance.registration_type === 'EMAIL'"
			class="col-12 settings-wrapper"
		>
			<q-input
				label="Subdomain"
				v-model="subdomain"
				class="q-mt-md"
				outlined
				bottom-slots
				:error="rti_validation_errors['subdomain'] ? true : false"
				:error-message="subdomain_error_message"
			/>
		</div>
		<!-- SMS Settings -->
		<div
			v-if="instance.registration_type === 'SMS'"
			class="col-12 settings-wrapper"
		>
			<q-select
				label="Registration Phone Numbers"
				type="number"
				outlined
				multiple
				class="q-mt-md"
				:value="instance.selected_numbers"
				@add="selectNumber($event, 'add')"
				@remove="selectNumber($event, 'remove')"
				@clear="selectNumber([], 'clear')"
				:options="available_numbers"
				option-label="number"
				map-options
				clearable
				:error="rti_validation_errors['selected_numbers'] ? true : false"
				:error-message="rti_validation_errors['selected_numbers']"
			>
			</q-select>
			<div class="col-12">
				<q-input
					outlined
					label="Registration Timeout"
					v-model="registration_timeout"
					type="number"
					suffix="minutes"
					:error="rti_validation_errors['registration_timeout'] ? true : false"
					:error-message="registration_timeout_error_message"
					onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190"
				/>
			</div>
			<div class="col-12">
				<q-checkbox
					class="flex justify-between full-width q-mb-md"
					left-label
					v-model="has_expiry_date"
					label="Enable expiry date"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import TimeZonePicker from '@/components/utils/dropdowns/TimeZonePicker.vue'

import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'RegistrationFields',
	components: {
		TimeZonePicker,
	},
	data(){
		return{
			has_subdomain_error: false,
			instance_index: 0
		}
	},
	computed: {
		...mapGetters(['available_numbers', 'rti_validation_errors']),
		instance: function () {
			return this.$store.getters.registration_template_instances[
				this.instance_index
			]
		},
		has_expiry_date: {
			get(){
				return this.instance.has_expiry_date || false
			},
			set(value) {
				this.updateRTI({index: this.instance_index, property: 'has_expiry_date', value})
			}
		},
		friendly_name: {
			get(){
				return this.instance.friendly_name || ''
			},
			set(value) {
				this.updateRTI({index: this.instance_index, property: 'friendly_name', value})
			}
		},
		subdomain: {
			get(){
				return this.instance.subdomain || ''
			},
			set(value) {
				this.has_subdomain_error = false
				this.$store.commit('setErrorMessage', {})
				let subdomain_error_message = ''
				value = value.toLowerCase().trim().replace(' ', '-').replace('_', '-')
				if (value.length > 0 && !value.match(/^[\w\d\-\s_]+$/i)){
					this.has_subdomain_error = true
					subdomain_error_message = value + '  is not a valid subdomain.'
					this.$store.commit('setErrorMessage', {'subdomain': subdomain_error_message})
				}
				this.updateRTI({index: this.instance_index, property: 'subdomain', value})
			}
		},
		registration_timeout: {
			get(){
				return this.instance.registration_timeout || ''
			},
			set(value) {
				value = value <= 0 ? 10 : parseInt(value)
				this.updateRTI({index: this.instance_index, property: 'registration_timeout', value})
			}
		},
		customer_groups: function () {
			return this.$store.getters.customer_groups.map((cg) => ({
				value: cg.id,
				label: cg.name,
			}))
		},
		subdomain_error_message () {
			return Array.isArray(this.rti_validation_errors['subdomain']) ?
				this.rti_validation_errors['subdomain'][0] : this.rti_validation_errors['subdomain']
		},
		registration_timeout_error_message () {
			return Array.isArray(this.rti_validation_errors['registration_timeout']) ?
				this.rti_validation_errors['registration_timeout'][0] : this.rti_validation_errors['registration_timeout']
		}
	},
	methods: {
		...mapActions(['getRTIMappings', 'updateRTI']),
		updateInstance(value, prop) {
			if (value == 'EMAIL'){
				this.selectNumber([], 'clear')
			}else if(value == 'SMS'){
				this.subdomain = ''
				this.has_subdomain_error = false
			}
			this.$store.commit('updateInstance', {
				value,
				prop,
				index: this.instance_index,
			})
		},
		selectNumber({ value }, operation) {
			this.$store.commit('updateRTIPhoneNumbers', {
				index: this.instance_index,
				value,
				operation,
			})
		},
		async setDataset({ value }) {
			this.updateInstance(value, 'dataset')
			await this.getRTIMappings(this.instance_index)
			if (this.instance.registration_type) {
				this.setRegistrationType(this.instance.registration_type)
			}
		},
		setRegistrationType(value) {
			this.updateInstance(value, 'registration_type')
			this.$store.dispatch('setFields', this.instance_index)
			if (this.available_numbers.length) {
				this.registration_type_options=['EMAIL', 'SMS']
			}
		},
	},
	created() {
		this.instance_index = this.$route.params.instance_index
		this.registration_type_options = ['EMAIL']
		if (this.available_numbers.length) {
			this.registration_type_options.push('SMS')
		}
	}
}
</script>
